/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    code: "code",
    hr: "hr",
    p: "p",
    ol: "ol",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components), {Boss, Trait, Boon, Item, Instability, Skill, Phase, CMInformation, InformationBlock, IdealRotation, Effect, Condition, Specialization} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!CMInformation) _missingMdxReference("CMInformation", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!IdealRotation) _missingMdxReference("IdealRotation", true);
  if (!InformationBlock) _missingMdxReference("InformationBlock", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Phase) _missingMdxReference("Phase", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "lAIxOQlt_jI",
    timestamp: "235",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Severance",
    weapon2MainSigil2: "Impact",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Trait, {
    name: "Lasting Legacy",
    id: "2100"
  }), " to maintain decent party ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Item, {
    name: "Celerity",
    id: "24865"
  }), " on Staff if you notice a drop in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Social Awkwardness"
  }), " is present you can share ", React.createElement(Boon, {
    name: "Stability"
  }), " at key moment in the fight via ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), ".", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In this case take ", React.createElement(Trait, {
    name: "SpiritBoon",
    id: "1774"
  }), " to share a stack whenever you swap to ", React.createElement(Skill, {
    name: "LegendaryDwarfStance",
    id: "28419"
  }), "."), "\n"), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present take ", React.createElement(Item, {
    name: "absorption",
    id: "72872"
  }), " on both weapon sets."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " is present you can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Cast your ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " summons on the north/north-east side of the invisible hitbox to prevent entering in combat too early."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Before mama becomes vulnerable precast ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), " then swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".", React.createElement("br"), "\r\nOn sword, cast ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " into ", React.createElement(Effect, {
    name: "exposed"
  }), " and prepare for knight 1."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In a slower party it is higher damage to cast ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " after ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " as you can profit from ", React.createElement(Trait, {
    name: "Unsuspecting Strikes",
    id: "1767"
  }), " in ", React.createElement(Effect, {
    name: "exposed"
  }), "."), "\n", React.createElement(_components.li, null, "If your party is missing damage on the adds keep ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " for knight 1.", React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 1"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to instantly apply ", React.createElement(Condition, {
    name: "vulnerability"
  }), " and ", React.createElement(Condition, {
    name: "Immobile"
  }), " on the add.", React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and cast ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " into ", React.createElement(Effect, {
    name: "exposed"
  }), " then precast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " into p2.", React.createElement("br"), React.createElement("br")))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Burst the phase with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ".", React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Might"
  }), " then prepare for knight 2.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your party is missing damage you may want to fit another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ". In this case you will want to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), " swap earlier."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " (Knight 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 2"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, null, React.createElement(_components.p, null, "If available instant cast ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " before the add is visible.", React.createElement("br"), "\r\nPrecast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " to break the add."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), " at or below 10% energy to proc ", React.createElement(Trait, {
    name: "Charged mists",
    id: "1791"
  }), "."), "\n"), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "vulnerability"
  }), " while dealing moderate damage and fit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed.", React.createElement("br"), React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), " before p3 starts."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), " then swap to ", React.createElement(Skill, {
    name: "LegendaryRenegadeStance",
    id: "41858"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you do not have a way to avoid damage in this phase, you can use ", React.createElement(Skill, {
    name: "Unrelenting Assault",
    id: "26699"
  }), " after ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " as it is a two seconds evade."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "LegendaryRenegadeStance",
    id: "41858"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 3"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " to break the add and use ", React.createElement(Skill, {
    name: "Breakrazors Bastion",
    id: "45686"
  }), " if needed.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "vulnerability"
  }), " and ", React.createElement(Condition, {
    name: "Immobile"
  }), ".", React.createElement("br"), "\r\nDeal moderate damage with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", share ", React.createElement(Boon, {
    name: "might"
  }), " then weapon swap to staff.", React.createElement("br"), React.createElement("br"), React.createElement("br")))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and use ", React.createElement(Skill, {
    name: "Warding Rift",
    id: "29288"
  }), " to mitigate the knock back shockwaves.", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " to break the boss and profit from ", React.createElement(Item, {
    name: "Severance",
    id: "84505"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "exposed"
  }), " burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Share ", React.createElement(Boon, {
    name: "alacrity"
  }), " and ", React.createElement(Boon, {
    name: "might"
  }), " whenever possible.", React.createElement("br")), "\n"), React.createElement(_components.p, null, "Keep dealing damage with auto attack and ", React.createElement(Skill, {
    name: "Menders Rebuke",
    id: "29145"
  }), " until the boss dies."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If the party is missing damage, you will have time to burst again with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Menders Rebuke",
    id: "29145"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "siax",
    video: "lAIxOQlt_jI",
    timestamp: "290",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Severance",
    weapon2MainSigil2: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Suggested Traits:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Lasting Legacy",
    id: "2100"
  }), " to maintain decent party ", React.createElement(Boon, {
    name: "Might"
  }), " if needed."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Vindication",
    id: "2094"
  }), " to maximize damage on good instability days."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Righteous Rebel",
    id: "2182"
  }), " on bad instability days."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Social Awkwardness"
  }), " is present you can share ", React.createElement(Boon, {
    name: "Stability"
  }), " at key moment in the fight via ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), ".", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In this case take ", React.createElement(Trait, {
    name: "SpiritBoon",
    id: "1774"
  }), " to share a stack whenever you swap to ", React.createElement(Skill, {
    name: "LegendaryDwarfStance",
    id: "28419"
  }), "."), "\n"), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " is present you can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Take portal and cast your ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " summons then double swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " to reset your energy.\r\nPrecast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and prepare for p1."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " while swapping to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " enabling ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), ".", React.createElement("br"), "\r\nFit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed then prepare to kill your add in split."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " to teleport to your add and cast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "vulnerability"
  }), " on it, then finish the add with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed.", React.createElement("br"), "\r\nAvoid the damaging orange AoE while coming back to mid, prepare for p2."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), ", move ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " into the start of p2."), "\n", React.createElement(_components.li, null, "Ask your favourite ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " to ", React.createElement(Skill, {
    name: "Flame trap",
    id: "12499"
  }), " your add without ", React.createElement(Instability, {
    name: "Outflanked"
  }), " ."), "\n", React.createElement(_components.li, null, "You can use a portal to get to your add faster instead of walking or using ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " (into p2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "alacrity"
  }), " and ", React.createElement(Boon, {
    name: "might"
  }), ".", React.createElement("br"), "\r\nFit in ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if possible and swap to ", React.createElement(Skill, {
    name: "LegendaryRenegadeStance",
    id: "41858"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " in the middle of the hitbox and burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " inside the hitbox under ", React.createElement(Effect, {
    name: "exposed"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In need, you can cleave the adds while avoiding any damage with ", React.createElement(Skill, {
    name: "Unrelenting Assault",
    id: "26699"
  }), ".\r\n(Careful to not cripple your ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " damage when ", React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " is to be used.)"), "\n"), React.createElement(_components.p, null, "Cast another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed and at the end of the phase share ", React.createElement(Boon, {
    name: "might"
  }), ".\r\nPrepare for split.", React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), " (if needed)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "LegendaryRenegadeStance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "vulnerability"
  }), " when the add spawns.", React.createElement("br"), "\r\nCleave it with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and some auto attack then use ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " to teleport to the boss at the start of the phase."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), ", move ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " into the start of p3."), "\n", React.createElement(_components.li, null, "Ask your favourite ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " to ", React.createElement(Skill, {
    name: "Flame trap",
    id: "12499"
  }), " your add without ", React.createElement(Instability, {
    name: "Outflanked"
  }), " ."), "\n", React.createElement(_components.li, null, "You can use a portal to get to your add or comeback to the boss faster instead of walking or using ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " (into p3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "alacrity"
  }), " and ", React.createElement(Boon, {
    name: "might"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " in the middle of the hitbox, swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " and activate ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), " to maximize ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " hits under ", React.createElement(Trait, {
    name: "Swift Termination",
    id: "1800"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " inside the hitbox under ", React.createElement(Effect, {
    name: "exposed"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In need, you can cleave the adds while avoiding any damage with ", React.createElement(Skill, {
    name: "Unrelenting Assault",
    id: "26699"
  }), ".\r\n(Careful to not cripple your ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " damage when ", React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " is to be used.)"), "\n"), React.createElement(_components.p, null, "Fit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed.")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryAssassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "lAIxOQlt_jI",
    timestamp: "344",
    videoCreator: "Inky",
    foodId: "91805",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Severance",
    weapon2MainSigil2: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "91805",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "20%"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Trait, {
    name: "Righteous Rebel",
    id: "2182"
  }), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice significant downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n", React.createElement(_components.li, null, "Make sure a ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " takes ", React.createElement(Skill, {
    name: "moastance",
    id: "45970"
  }), " or take ", React.createElement(_components.code, null, "35%"), " Boon Duration."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " is present you can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Precast your ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " summons when the boss is started.", React.createElement("br"), "\r\nPrepare for p1."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " before the boss is vulnerable.", React.createElement("br"), "\r\nSwap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), " at or below 10% energy to proc ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "exposed"
  }), " burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Disable ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), " to fit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and reactivate it to fully empty your energy."), "\n"), React.createElement(_components.p, null, "Swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " after the dome explosion.", React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", cast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ".", React.createElement("br"), "\r\nAfter the tail swipe burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and any available sword skills depending of the % of the boss."), React.createElement(_components.p, null, "Swap to staff when the boss is phased and prepare to capture North circle."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can tank the boss by staying in front of it after the dome explosion while your party goes to stack behind, watch out for the auto attack (a right handed dagger hit).", React.createElement("br"), "By moving inside the hitbox to rejoin your party behind the boss, you will trigger the tail swipe attack which will delay the orange blast AoE by one auto."), "\n")), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "Post capture"
  }, React.createElement(_components.p, null, "When the phase starts precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " to apply ", React.createElement(Condition, {
    name: "vulnerability"
  }), " alongside ", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), ", then wait to see ", React.createElement(Skill, {
    name: "moastance",
    id: "45970"
  }), " on your buff bar to share ", React.createElement(Boon, {
    name: "alacrity"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "soulcleavessummit",
    id: "45773"
  }), " and share ", React.createElement(Boon, {
    name: "might"
  }), " when you get 5 stacks of Kalla's Fervor.")), React.createElement(InformationBlock, {
    title: "Phase's"
  }, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), ".", React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "exposed"
  }), " burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Disable ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), " to fit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and reactivate it to fully empty your energy."), "\n"), React.createElement(_components.p, null, "Swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " after the dome explosion.", React.createElement("br"), "\r\nShare ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", cast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ".", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and any available sword skills depending of the % of the boss.", React.createElement("br"), React.createElement("br"), "\r\nSwap to staff when the boss is phased and prepare go to North circle for the second capture mini-game.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you do not play with a ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " (", React.createElement(Boon, {
    name: "Aegis"
  }), ") try to fit ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), " after ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " to avoid the orange blast AoE."), "\n")), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Post Capture", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), " with ", React.createElement(Skill, {
    name: "moastance",
    id: "45970"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "soulcleavessummit",
    id: "45773"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "lAIxOQlt_jI",
    timestamp: "",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Impact",
    weapon2MainSigil2: "Force",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24868",
    weapon2MainSigil2Id: "24615"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "35%"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Trait, {
    name: "righteousrebel",
    id: "2182"
  }), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Without ", React.createElement(Instability, {
    name: "Outflanked"
  }), " you can share ", React.createElement(Boon, {
    name: "Stability"
  }), " at key moment in the fight via ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), ".", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In this case take ", React.createElement(Trait, {
    name: "SpiritBoon",
    id: "1774"
  }), " to share a stack whenever you swap to ", React.createElement(Skill, {
    name: "LegendaryDwarfStance",
    id: "28419"
  }), "."), "\n"), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " is present you can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Precast your ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " summons then double swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "  to reset your energy."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " you can cast ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), " while going back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " when the boss becomes attackable.", React.createElement("br"), "\r\nSwap to ", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), " at or below ", React.createElement(_components.code, null, "10%"), " energy to proc ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " under ", React.createElement(Skill, {
    name: "vengefulhammers",
    id: "26557"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you are not in voice for the starting countdown, you can easily determine when to precast\r\nby watching the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " or ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " traps getting highlighted on the ground."), "\n", React.createElement(_components.li, null, "In a slower party it is higher damage to cast ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " after ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " as you can profit from ", React.createElement(Trait, {
    name: "unsuspectingstrikes",
    id: "1767"
  }), " in ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), "\n", React.createElement(_components.li, null, "At the end of p1 share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), " if the team didn't blast properly."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precasted)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "Island 1"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and share ", React.createElement(Boon, {
    name: "alacrity"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your party is missing damage or without ", React.createElement(Instability, {
    name: "outflanked"
  }), ", share ", React.createElement(Boon, {
    name: "stability"
  }), " via ", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), "."), "\n")), React.createElement(InformationBlock, {
    title: "Island 2"
  }, React.createElement(_components.p, null, "Instant ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and share ", React.createElement(Boon, {
    name: "might"
  }), " afterwards.", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Activate ", React.createElement(Skill, {
    name: "vengefulhammers",
    id: "26557"
  }), " if you have too much energy.", React.createElement("br"), "\r\nIf you didn't get ", React.createElement(Effect, {
    name: "stealth"
  }), " from your ", React.createElement(Specialization, {
    name: "Scrapper"
  }), " on the first island, share ", React.createElement(Boon, {
    name: "might"
  }), " and jump over the anomaly while resuming the rotation."), "\n")), React.createElement(InformationBlock, {
    title: "Island 3"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), " to provide further ", React.createElement(Boon, {
    name: "stability"
  }), ". Cast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " followed by  ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed.", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " going to the next island so that your legend cd is off cooldown for p2."), "\n")), React.createElement(InformationBlock, {
    title: "Island 4"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "might"
  }), " and ", React.createElement(Boon, {
    name: "alacrity"
  }), ".\r\nWithout ", React.createElement(Instability, {
    name: "outflanked"
  }), " jump over the anomaly while casting ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and take portal to p2."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you have more than ", React.createElement(_components.code, null, "50%"), " energy turn ", React.createElement(Skill, {
    name: "soulcleavessummit",
    id: "45773"
  }), " on and off."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 1")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 2")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 3")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 4")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " to apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Make sure to have at least ", React.createElement(_components.code, null, "20%"), " energy to start the phase."), "\n"), React.createElement(_components.p, null, "In ", React.createElement(Effect, {
    name: "exposed"
  }), " under ", React.createElement(Skill, {
    name: "vengefulhammers",
    id: "26557"
  }), ", burst with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), ".\r\nPlace your portal and finish the phase by sharing ", React.createElement(Boon, {
    name: "Might"
  }), " and using ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Start your burst with ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " if your party lacks ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "Island 1"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " then take portal.")), React.createElement(InformationBlock, {
    title: "Island 2"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "might"
  }), " and ", React.createElement(Boon, {
    name: "stability"
  }), " via ", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), ".", React.createElement("br"), "\r\nUse ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " before taking portal.")), React.createElement(InformationBlock, {
    title: "Island 3"
  }, React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to apply instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on the anomaly.", React.createElement("br"), "\r\nFit in a ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed then take portal.")), React.createElement(InformationBlock, {
    title: "Island 4"
  }, React.createElement(_components.p, null, "Open your portal in the hitbox and share ", React.createElement(Boon, {
    name: "Might"
  }), " if needed. Deal moderate damage to the anomaly via ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and/or ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " before taking portal."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your party lacks damage it is advised to use ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " while jumping over the anomaly."), "\n")), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 1")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 2")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 3")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 4")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "Alacrity"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " to apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " under ", React.createElement(Skill, {
    name: "vengefulhammers",
    id: "26557"
  }), ".", React.createElement("br"), "\r\nFinally use any available sword skills to finish the boss."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Start your burst with ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " if your party lacks ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application."), "\n", React.createElement(_components.li, null, "Don't ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " if the boss will die as the animation is too slow to be worth the cast."), "\n")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendarydwarfstance",
    id: "28419"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "lAIxOQlt_jI",
    timestamp: "68",
    videoCreator: "Inky",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Force",
    weapon2MainSigil2: "Severance",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "84505"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "35%"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Trait, {
    name: "righteousrebel",
    id: "2182"
  }), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Item, {
    name: "celerity",
    id: "24865"
  }), " on Staff if you notice a drop in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".")), React.createElement(InformationBlock, {
    title: "On Boss"
  }, React.createElement(_components.p, null, "Precast your ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " summons then swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "inspiringreinforcement",
    id: "28516"
  }), " if your ", React.createElement(Specialization, {
    name: "Scrapper"
  }), " doesn't precast ", React.createElement(Skill, {
    name: "defensefield",
    id: "30027"
  }), " to share ", React.createElement(Boon, {
    name: "Stability"
  }), "."), "\n")))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendarycentaurstance",
    id: "28195"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), " (repeat)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Start the phase on ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), ".", React.createElement("br"), "\r\nInstant burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), ".", React.createElement("br"), "\r\nSwap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " while moving behind the boss, then use ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), ", ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " and another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nAvoid the knock back with ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), ", once in the corner swap to staff and use ", React.createElement(Skill, {
    name: "mendersrebuke",
    id: "29145"
  }), " if needed to phase the boss."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can cancel ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), " with a weapon swap and a dodge to prevent the wave to knock you down."), "\n")), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "On Boss (Precast)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "mendersrebuke",
    id: "29145"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), " at the center of the arena before breaking your corner with ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), ".\r\nUse SAK to instantly go back to mid position.", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " when everyone is in range.", React.createElement("br")), "\n"), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "breakrazorsbastion",
    id: "45686"
  }), " if needed, then swap to sword and prepare for p2."), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "breakrazorsbastion",
    id: "45686"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " to instantly apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ".", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " and ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), ".", React.createElement("br"), "\r\nCast ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " close to ", React.createElement(_components.code, null, "50%"), " to benefit from ", React.createElement(Trait, {
    name: "swifttermination",
    id: "1800"
  }), " then finish the phase by sharing ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Swap to staff and precast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " on the north-west add, use ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), " to empty your energy then swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), ".", React.createElement("br"), "\r\nUse ", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), " on west add and instant SAK back to mid position.", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " when everyone is in range.", React.createElement("br")), "\n"), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "breakrazorsbastion",
    id: "45686"
  }), " if needed, then swap to sword and prepare for p3."), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "breakrazorsbastion",
    id: "45686"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), ".", React.createElement("br"), "\r\nIf needed share ", React.createElement(Boon, {
    name: "Might"
  }), " and fit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " before the boss teleports away."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your party is missing damage, use SAK to go into the hitbox after the boss teleports away and use any skills available like ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " until it has phased."), "\n", React.createElement(_components.li, null, "If your party is missing ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ", like in p2 you can precast ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " before ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n"))), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "lAIxOQlt_jI",
    timestamp: "133",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Sword",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Sword",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Staff",
    weapon2MainSigil1: "Force",
    weapon2MainSigil2: "Severance",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "lAIxOQlt_jI",
    videoCreator: "Inky",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "84505"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Suggested Boon Duration: ", React.createElement(_components.code, null, "35%"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Trait, {
    name: "righteousrebel",
    id: "2182"
  }), "."), "\n", React.createElement(_components.li, null, "Increase boon duration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Item, {
    name: "Celerity",
    id: "24865"
  }), " on Staff if you notice a drop in ", React.createElement(Boon, {
    name: "Quickness"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " is present take ", React.createElement(Item, {
    name: "absorption",
    id: "72872"
  }), " on both weapon sets."), "\n"), React.createElement(_components.hr), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If ", React.createElement(Instability, {
    name: "Afflicted"
  }), ", ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "FluxBomb"
  }), " is present you can precast ", React.createElement(Boon, {
    name: "Resistance"
  }), " via ", React.createElement(Skill, {
    name: "LegendaryDemonStance",
    id: "28494"
  }), " skill ", React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(_components.hr), React.createElement(InformationBlock, {
    title: "At the Mistlock"
  }, React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and blast the fire field to gain ", React.createElement(Boon, {
    name: "Might"
  }), ".", React.createElement("br"), "\r\nIf you are on fire field duty use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ". Finally swap to ", React.createElement(Skill, {
    name: "LegendaryAssassinStance",
    id: "28134"
  }), " to invoke your ", React.createElement(Skill, {
    name: "enchanted daggers",
    id: "26937"
  }), " and swap back to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Trigger the boss and cast ", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), " to prepare your burst."), "\n")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can blast ", React.createElement(Boon, {
    name: "Might"
  }), " via the special action key (SAK)."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "renewingwave",
    id: "29321"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "enchanteddaggers",
    id: "26937"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100 to 80%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), ".", React.createElement("br"), "\r\nBurst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), " then do the usual combo\r\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), ".", React.createElement("br"), "\r\nOnce phased, SAK to the anomaly and finish it then swap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Do not move your character after starting the boss, you will be teleported in the center of the hitbox on a ", React.createElement(_components.code, null, "1x1"), " pixel causing you to negate Arkk fear attack."), "\n"), React.createElement("br"), React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "darkrazorsdaring",
    id: "41220"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 (80 to 70%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), ", share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " whenever possible.", React.createElement("br"), " Break the boss with ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " then swap to sword.", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " into SAK to get ", React.createElement(_components.code, null, "x9"), " hits."), "\n", React.createElement(_components.li, null, "Be sure to build 5 stacks of Kalla's Fervor before sharing ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "."), "\n")), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Archdiviner"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Deal moderate damage with ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " and some auto, if the Archdiviner gets a break bar instant SAK to break it.\r\nWhen the phase ends, share ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "alacrity"
  }), "."), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3 (70 to 50%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), " under ", React.createElement(Skill, {
    name: "impossible odds",
    id: "27107"
  }), ", burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), ".\r\nFit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if needed.", React.createElement("br"), React.createElement("br"), "\r\nSwap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " and share ", React.createElement(Boon, {
    name: "Might"
  }), " at the end of the phase."), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4 (50 to 40%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), ", share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " whenever possible.", React.createElement("br"), " Break the boss with ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " then swap to sword.", React.createElement("br"), React.createElement("br"), "\r\nIn ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " into SAK to get ", React.createElement(_components.code, null, "x9"), " hits."), "\n", React.createElement(_components.li, null, "Be sure to build 5 stacks of Kalla's Fervor before sharing ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " casting time to not face Arkk when the fear attack is casted."), "\n")), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Gladiator"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ". You can share ", React.createElement(Boon, {
    name: "Might"
  }), " or ", React.createElement(Boon, {
    name: "Alacrity"
  }), " depending on your energy."), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can cleave the adds with ", React.createElement(Skill, {
    name: "unrelentingassault",
    id: "26699"
  }), " when your ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " or ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " pull them to you."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 5 (40 to 30%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), ".", React.createElement("br")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " or ", React.createElement(Boon, {
    name: "Might"
  }), " depending on your energy."), "\n"), React.createElement(_components.p, null, "In ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and any sword skills available under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nSwap to ", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), " at the end of the phase and share ", React.createElement(Boon, {
    name: "Might"
  }), " if needed."), React.createElement("br"), React.createElement("br"), React.createElement("br")), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryrenegadestance",
    id: "41858"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 6 (30 to 0%)"
  }, React.createElement(_components.hr), React.createElement(_components.p, null, "Create a fire field with ", React.createElement(Skill, {
    name: "soulcleavessummit",
    id: "45773"
  }), ", precast ", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), " and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), ".", React.createElement("br"), "\r\nBreak the boss with ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " then swap to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Blast your fire field with SAK while doing ", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), " then share extra ", React.createElement(Boon, {
    name: "Might"
  }), " via ", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "."), "\n"), React.createElement(_components.p, null, "In ", React.createElement(Effect, {
    name: "Exposed"
  }), ", burst with the usual combo ", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), " under ", React.createElement(Skill, {
    name: "impossibleodds",
    id: "27107"
  }), ".", React.createElement("br"), React.createElement("br"), "\r\nFit in another ", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), " if your party lacks damage.", React.createElement("br"), React.createElement("br"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Precasted", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "soulcleavessummit",
    id: "45773"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "icerazorsire",
    id: "40485"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ordersfromabove",
    id: "45537"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "surgeofthemists",
    id: "28978"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "heroiccommand",
    id: "44076"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chillingisolation",
    id: "29233"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "citadelbombardment",
    id: "41294"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shacklingwave",
    id: "28472"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deathstrike",
    id: "27074"
  }), "\n"), "\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
